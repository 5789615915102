import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import AboutUs from '../components/AboutUs/AboutUs';
import ArticleSection from '../components/ArticleSection/ArticleSection';
import FeatureSectionGridThree from '../components/FeatureSectionGridThree/FeatureSectionGridThree';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import HeroSection from '../components/HeroSection/HeroSection';
import NewsSection from '../components/NewsSection/NewsSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const CorporateIndex = ({ data, location }) => {
  const { nanoCms } = useStaticQuery(graphql`
    query {
      nanoCms {
        articles(isPublished: true, category: "お知らせ", limit: 3) {
          edges {
            node {
              title
              url
              updatedAt
            }
          }
        }
      }
    }
  `);
  // GraphQLデータからニュース項目を生成
  const newsItems = nanoCms.articles.edges.map(({ node }) => ({
    id: node.url, // 一意のIDが必要な場合、URLをIDとして使用
    postDate: node.updatedAt, // 投稿日
    content: node.title, // コンテンツ(タイトル)
    url: `/articles/${node.url}`, // URL
  }));
  return (
    <Layout>
      <HeroSection title="" imageSrc="/ver2/top-banner6.jpg" />
      <NewsSection items={newsItems} />
      <ArticleSection
        title="企業理念"
        subTitle="MISSION"
        description={`
        わたくしたちは、「食の未来をデジタルで切り拓く」ことを
        ミッションとしています。

        目利きのスタッフが世界中を飛びまわり、満足度の高い“本物”のみを厳選し
        インターネットを通じてひとりでも多くのお客様の心を豊かにしてまいります。

        情報化社会の急速な発展がめまぐるしい昨今、世界中では日々新しい価値が生み出されております。
        その膨大な中から本当に価値あるものを長年培ってきた見極める「力」をもって、
        いち早く皆様へ発信できるよう一切の妥協をせず、日々実行し続けます。

        「新しい価値を不変の価値にかえていく企業」であり続けるビジョンのもと、
        社会に貢献する一員として、永続的に進化することを目指します。
        `}
        sectionId="section1"
        ctaLink="/init"
      />
      <FeatureSectionGridThree
        title="選ばれる理由"
        subTitle="REASON"
        items={[
          {
            title: '30年以上に渡る仕入れ実績',
            description:
              '目利きの現地バイヤーがヨーロッパ、アメリカで直接買い付け',
            imageSrc: '/reason101.jpg',
            imageAlt: '30年以上に渡る仕入れ実績',
          },
          {
            title: '大量買い付けにより安値を実現',
            description:
              '貴重な商品から人気商品まで多様な商品を取り揃えております',
            imageSrc: '/reason103.jpg',
            imageAlt: '大量買い付けにより安値を実現',
          },
          {
            title: '豊富な販売網',
            description:
              '大手ECモールをはじめお客様のニーズに合わせ、数多くのECサイトでお買い求め頂けます。',
            imageSrc: '/reason102.jpg',
            imageAlt: '豊富な販売網',
          },
        ]}
        sectionId="section2"
        ctaLink="/init"
      />
      <AboutUs
        imageSrc="/corporate/img-business.webp"
        imageAlt="Example image"
        title="案内"
        subTitle="OUTLINE"
        sectionId="outline"
        items={[
          {
            title: '社名',
            description: 'シェスタ株式会社',
          },
          {
            title: '設立',
            description: '平成5年4月',
          },
          {
            title: '代表取締役',
            description: '皆川 幸司',
          },
          {
            title: '従業員数',
            description: '15名',
          },
          {
            title: '所在地',
            description:
              '〒111-0041 \n 東京都台東区元浅草4-10-6　AUSPICE元浅草9F',
          },
          {
            title: '事業内容',
            description:
              '・海外ブランド品の輸入・販売･卸し \n ・プライベートブランド商品の開発',
          },
          {
            title: '取引銀行',
            description:
              'みずほ銀行 / 千束町支店 \n 三菱東京UFJ銀行 / 浅草橋支店',
          },
        ]}
      />

      <FeatureSectionLeft
        imageSrc="/aacd.png"
        imageAlt="Example image"
        title="安心の証"
        subTitle="SERVICES"
        description="シェスタ株式会社は日本流通自主管理協会の会員企業です。"
        sectionId="section1"
        ctrText="詳しくはこちら"
        ctrLink="https://www.aacd.gr.jp/"
      />
    </Layout>
  );
};

export default CorporateIndex;

export const Head = () => (
  <Seo
    description="ブランドショップ「リヴェラール」を運営するシェスタ株式会社の公式HPです。海外ブランド品の輸入・販売･卸し、プライベートブランド商品の開発。「世界中の価値ある物を通じて、人々や社会に常に新しい価値を提供する」ことを
  ミッションとしています。"
  />
);

// src/components/NewsSection.tsx

import React from 'react';
import { Link } from 'gatsby';
import * as styles from './NewsSection.module.css';

type ColorType = 'primary' | 'secondary';

interface NewsItem {
  id: number;
  postDate: string;
  content: string;
  url?: string;
}

interface NewsSectionProps {
  colorType: ColorType;
  items: NewsItem[];
}

const NewsSection: React.FC<NewsSectionProps> = ({ items, colorType }) => {
  let newsSectionClass = styles.newsSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    newsSectionClass = styles.newsSectionPrimary;
  } else if (colorType === 'secondary') {
    newsSectionClass = styles.newsSectionSecondary;
  }
  return (
    <section className={newsSectionClass} id="news">
      <div className={styles.newsSectionInner}>
        <h2 className={styles.header}>News</h2>
        <div>
          {items.map((newsItem: NewsItem) => (
            <div key={newsItem.id} className={styles.newsItem}>
              <h3 className={styles.newsPostDate}>{newsItem.postDate}</h3>
              {newsItem.url ? (
                <Link to={newsItem.url} className={styles.newsContent}>
                  {newsItem.content}
                </Link>
              ) : (
                <p className={styles.newsContent}>{newsItem.content}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
